<template>
	<div style="width: 100%;">
		<table class="custom-table">
			<tbody>
			<tr v-for="(row, rowIndex) in value" :key="rowIndex" >
				<td>
					<span v-if="lookTableValue">{{row.key}}</span>
					<el-input v-else v-model="row.key">
							<template slot="prepend">
								<el-button type="danger" icon="el-icon-delete" @click="delValue(rowIndex)"></el-button>
							</template>
							<template slot="append">
								<el-button type="info" icon="el-icon-circle-plus-outline" @click="addValue(rowIndex)"></el-button>
							</template>
					</el-input>
				</td>
				<td v-if="row.value.length>0"><DynamicTable :value="row.value" :lookTableValue = "lookTableValue"></DynamicTable></td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "DynamicTable",
	props: {
		value: {
			type: Array,
			required: true
		},
		lookTableValue: {
			type: Boolean,
		},
	},
	components: {
	
	},
	
	methods: {
		addValue(index){
			let obj = {
				key: "",
				value:[]
			};
			this.value[index].value.push(obj)
		},
		delValue(index){
			this.value.splice(index,1)
		},
	}
};
</script>

<style scoped>
.custom-table {
	text-align: center;
	background:#fff;
	border-collapse: collapse;
	width: 100%;
	color:#000a;
}

.custom-table td {
	border: 1px solid #d0d0d0;
	padding: 8px;
	
}
</style>
