<template>
    <div>
        <p style="font-size: 18px;color: #444444;margin: 20px 40px;">相关配件</p>
        <div style="margin: 20px 22px;width: 1500px;" >
            <div style="float: left;margin-left: 18px;" v-for="(it,ind) in 5" :key="ind">
                <div style="width: 270px;height: 350px;background: #FFFFFF;border: 1px solid #BCBCBC;">
                    <div style="width: 100%;height: 220px;">
                        <!-- 左侧图片  -->
                        <div style="display: flex">
                            <div style="width: 170px;background: rgb(109, 115, 123);;height: 120px;margin: 16px 0 0 18px;"></div>
                            <!-- 右侧图片  -->
                            <div style="margin-top: 16px;margin-left: 2px">
                                <div style="width: 63px;background: rgb(109, 115, 123);height: 59px"></div>
                                <div style="width: 63px;background: rgb(109, 115, 123);height: 59px;margin-top: 2px;"></div>
                            </div>
                        </div>
                        <div style="display: flex">
                            <div style="width: 170px;background: rgb(109, 115, 123);height: 70px;margin: 2px 0 0 18px;"></div>
                            <div style="width: 63px;background-color: rgb(109, 115, 123);height: 70px;margin: 2px"></div>
                        </div>
                    </div>
                    <div style="width: 270px;height: 80px;background: #FFFFFF;">
                        <p style="font-size: 16px;color: #444444;margin: 5px 18px 0 18px;">小米（MI）小米电视4A 32英寸高清智能网络液晶平板电视机</p>
                        <p style="font-size: 18px;color: #FF0000;margin: 14px 0 0 18px;float: left">￥799.00</p>
                        <p style="font-size: 16px;color: #FF9800;float: right;margin-right: 18px;margin-top: 14px;">3542<span style="color: #999999">加购</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {

    components: {

    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {

    },
    data () {
        return {

        }
    },
    created(){
    },

    mounted:function(){

    },
    methods:{
    }
}
</script>