<template>
    <div style="width: 100%;height:100%;display: flex">
        <!--   左侧图片部分  -->
        <div style="width: 40%;">
            <div style="width: 560px;height:auto;margin: 30px 0 0 40px;">
                <img v-if="images.length>0" :src="images[imagesIndex].url" style="width: 560px;height: 560px;border: 1px solid #DDDDDD;object-fit: cover;"
                     @mouseenter="showMask = true" @mouseleave="showMask = false" @mousemove="updateMaskPosition">
<!--                      <div class="mask" v-if="showMask" :style="{ top: maskPosition.top + 'px', left: maskPosition.left + 'px' }"></div>-->
           </div>
            <div class="imgs" v-if="images.length>0">
                <div v-for="(item,index) in images" :key="index">
                    <img :src="item.url" style="width: 82px;height: 82px;float: left;margin-left: 14px;cursor: pointer;object-fit: cover;" @click="changeImage(index)" :style="{ border: index === imagesIndex ? 'box-shadow: 0 0 10px #f00;' : '' }" alt="">
                </div>
            </div>
        </div>

        <!--  右侧  -->
        <div style="width: 55%;margin-top:30px;margin-left: 2%">
            <!--  标题部分 -->
            <div v-for="(label,vla) in GoodsInfo.label" :key="vla" style="height: 64px;font-size: 24px;float: left">{{label}}{{GoodsInfo.title}}</div>
            <!--  价格部分 -->
            <div style="width: 100%;clear: both">
                <div class="price" style="margin: 20px 22px;float: left">售价</div>
	            <div style="font-size: 28px;color: #EE3F4D;padding-top: 12px;float: left;font-weight:bold;cursor: pointer">￥<span v-if="matchedProducts.length===1">{{matchedProducts[0].price}}</span><span v-else>
		        {{ minPrice }}-{{ maxPrice }}元</span></div>
                <div style="color: #999999;height: 24px;float: right;margin: 16px 10px;">月销量800+</div>
                <div style="border-bottom: 1px solid #DDDDDD;clear: both;padding: 5px"></div>
            </div>
            <!--  配送范围  -->
            <div style="width: 100%">
                <div class="price" style="margin: 20px 22px;float: left">不配送地区</div>
                <div style="margin: 12px 14px;float: left">
                   <div v-for="(Info,index) in GoodsInfo.deliveryScope.cityName" :key="index" style="float: left">
                         <div style="width: 100%;height: 24px;float: left;margin-left:10px;margin-top: 10px">{{Info.join('\ ')}}</div>
                   </div>
                </div>
            </div>
            <div style="width: 100%;clear: both">
                <div class="price" style="margin: 20px 22px;float: left">物流方式</div>
                <div style="margin: 12px 14px;float: left">
                    <div style="width: 100%;height: 24px;float: left;margin-left:10px;margin-top: 10px">{{GoodsInfo.transport}}</div>
                </div>
            </div>
            <div style="width: 100%;clear: both">
                <div class="price" style="margin: 20px 22px;float: left">收货地址</div>
                <div style="margin: 16px 14px;float: left">
                    <el-select size="small" v-model="addressId" placeholder="请选择">
                        <el-option
                            v-for="item in addressData"
                            :key="item.id"
                            :label="item.deliveryScope.address + '(' + item.name + ')'"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <span @click="b('/user/money/7')" class="hand op" style=" color: #2970FF; margin:0 30px">管理收货地址</span>
                </div>
            </div>
            <!--  主属性  -->
            <div style="width: 100%;clear: both;display: flex" v-for="(spec,specIndex) in GoodsInfo.property.specs" :key="specIndex">
                <div class="price" style="margin: 20px 22px;float: left;width: 10%">{{ spec.key }}</div>
                <div style="width: 90%;margin-top: 20px;">
	                <label v-for="(value, valueIndex) in spec.values" :key="valueIndex">
		                <input
			                style="margin-left: 10px"
			                type="radio"
			                @change="updateSelectedAttributes(spec.key, value)"
			                :value="value"
			                :checked="isSelected(spec.key, value)"
		                />
		                {{ value }}
	                </label>
                </div>
            </div>
<!--	        /////////////-->
            <div style="width: 100%">
                <div style="border-top: 1px solid #DDDDDD;clear: both;padding: 5px"></div>
            </div>
            <div style="width: 100%;display: flex">
                <div style="width: 25%;margin: 20px 22px;">
                    <el-input-number v-model="num" @change="handleChange" step-strictly :min="1"></el-input-number>
                </div>
                <div style="width: 20%;margin-top: 20px">
                    <el-button style="width: 90%" type="primary" @click="createOrder" class="el-icon-position" v-if="matchedProducts[0].stock>0">立即购买</el-button>
                    <el-button style="width: 90%" type="info"   v-if="matchedProducts[0].stock == 0 && typeof this.selectData.skuCode !='undefined'">库存不足</el-button>
                    <el-button style="width: 90%"    v-if="matchedProducts[0].stock == 0 && typeof this.selectData.skuCode =='undefined'">选择规格</el-button>
                </div>
                <div style="width: 30%;margin-top: 20px">
                    <el-button style="width: 90%" @click="addToCart" type="danger" class="el-icon-shopping-cart-2" v-if="matchedProducts[0].stock>0">加入购物车</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['GoodsInfo', 'roomId', 'questId'],
    components: {

    },
    data () {
        return {
	        selectedAttributes: {},
	        images:[],
	        imagesIndex:0,
            num: 1,
            selectedIndex: -1,
            edIndex:-1,
            showMask: false,
            maskPosition: { top: 0, left: 0 },
            isMouseMoving: false,

            // 收货地址
            addressData: [],
            addressId: '',
            selectAddress: {},

            selectData: {},
            }
        },
    watch:{
        GoodsInfo(newGoodsInfo){
            this.images = newGoodsInfo.images;
        }

    },
    mounted:function(){
	    this.images = this.GoodsInfo.images;
        this.getAddress();
    },
	computed:{
		matchedProducts() {
			const selectedAttributes = this.selectedAttributes;
			return this.GoodsInfo.property.skuList.filter(sku => {
				for (const [attribute, value] of Object.entries(selectedAttributes)) {
					const attributeMatch = sku.specValues.some(specValue =>
						specValue[attribute] === value
					);
					if (!attributeMatch) {
						return false;
					}
				}
				return true;
			});
		},
		minPrice() {
			const matchedProducts = this.matchedProducts;
			if (matchedProducts.length > 0) {
				return Math.min(...matchedProducts.map(product => Number(product.price)));
			} else {
				return "";
			}
		},
		maxPrice() {
			const matchedProducts = this.matchedProducts;
			if (matchedProducts.length > 0) {
				return Math.max(...matchedProducts.map(product => Number(product.price)));
			} else {
				return "";
			}
		}
	},
    methods:{
        // 计算库存
        handleChange(value, oldValue) {
            let param = {};
            if (!this.selectData.skuCode) {
                this.utils.err('请选择产品规格');
                return;
            }
            param.skuCode = this.selectData.skuCode;
            param.goodsId = this.GoodsInfo.id;
            param.goodsCount = value;
            this.newApi.buyNowGoodsAddAndSubtract(param).then(res => {
                if (res.isSuccess === 1) {
                    if (res.data.checkGoodsInfo.goodsStatus === 1) {
                        this.num = value
                    } else {
                        this.utils.err(res.data.checkGoodsInfo.msg)
                        this.num = oldValue
                    }
                }
            })
        },

        // 获取收货地址
        getAddress() {
            this.newApi.getAddressList().then(res => {
                this.addressData = res.data;
                for (const item of this.addressData) {
                    if (item.isDefault === 1) {
                        this.addressId = item.id;
                        this.selectAddress = item
                    }
                }
            })
        },

        // 加入购物车
        addToCart() {
            let params = {};
            let beFrom = 3;
            let roomId = this.roomId ? this.roomId : 0;
            let questId = this.questId ? this.questId : 0;
            if (!this.addressId) {
                this.utils.err('选择收货地址');
                return false
            }
            if (JSON.stringify(this.selectedAttributes) !== '{}' && Object.keys(this.selectedAttributes).length === this.GoodsInfo.property.specs.length) {
                params = {
                    shopId: this.GoodsInfo.shopId,
                    goodsId: this.GoodsInfo.id,
                    goodsName: this.GoodsInfo.title,
                    beFrom: beFrom,
                    roomId: roomId,
                    questId: questId,
                    skuCode: this.selectData.skuCode,
                    goodsUnitPrice: this.matchedProducts[0].price,
                    goodsCount: this.num,
                    goodsPrice: this.matchedProducts[0].price * this.num,
                    addressId: this.addressId,
                }
                this.newApi.addGoodsCart(params).then(res => {
                    if (res.isSuccess === 1) {
                        if (!res.data.checkGoodsInfo) {
                            this.utils.sus(res.data)
                        } else {
                            this.utils.err(res.data.checkGoodsInfo.msg)
                        }
                    }
                })
            } else {
                this.utils.err('请选择产品规格')
            }
        },

        // 生成订单
        createOrder() {
            let beFrom = 3;
            let roomId = this.roomId ? this.roomId : 0;
            let questId = this.questId ? this.questId : 0;
            if (!this.addressId) {
                this.utils.err('选择收货地址');
                return false
            }
            let params = {
                shopId: this.GoodsInfo.shopId,
                goodsId: this.GoodsInfo.id,
                skuCode: this.selectData.skuCode,
                goodsUnitPrice: this.matchedProducts[0].price,
                goodsCount: this.num,
                goodsPrice: this.matchedProducts[0].price * this.num,
                addressId: this.addressId,
                beFrom: beFrom,
                roomId: roomId,
                questId: questId
            }
            if (JSON.stringify(this.selectedAttributes) !== '{}' && Object.keys(this.selectedAttributes).length === this.GoodsInfo.property.specs.length) {
                this.newApi.buyNowGoodsSettlement(params).then(res => {
                    if (res.isSuccess === 1) {
                        if (!res.data.checkGoodsInfo) {
                            const to = this.$router.resolve({
                                path: '/shop/createOrder',
                                query: {
                                    id: JSON.stringify(res.data),
                                }
                            })
                            window.open(to.href, '_blank')
                        } else {
                            this.utils.err(res.data.checkGoodsInfo.msg)
                        }
                    }
                })
            } else {
                this.utils.err('请选择产品规格')
            }
        },

	    isSelected(attribute, value) {
		    return this.selectedAttributes[attribute] === value;
	    },
	    updateSelectedAttributes(attribute, value) {
            this.num = 1;
            for (let i; i<this.selectedAttributes.length; i++){
                if (this.selectedAttribute[i].name === attribute){
                    this.edIndex = i
                }
            }
		    this.selectedAttributes = {
			    ...this.selectedAttributes,
			    [attribute]: value
		    };
            this.selectData = this.matchedProducts[0]
			if (this.matchedProducts[0].images.length>0){
				this.images = this.matchedProducts[0].images.map(item =>({url:item}))
			}else {
				this.images = this.GoodsInfo.images
			}
		    
		    this.imagesIndex=0
	    },
	    changeImage(index){
            this.imagesIndex = index
        },

        selectItem(ind) {
            if (ind === this.selectedIndex) {
                this.selectedIndex = -1; // 取消选中
            } else {
                this.selectedIndex = ind; // 切换选中
            }
        },
        Item_list(l){
            if (l === this.edIndex) {
                this.edIndex = -1; // 取消选中
            } else {
                this.edIndex = l; // 切换选中
            }
        },
        updateMaskPosition(event) {
            this.maskPosition = { top: event.clientY + 'px', left: event.clientX + 'px' };
            this.isMouseMoving = true;
        }
    }
}
</script>
<style scoped>
    .imgs {
       margin: 16px 0 0 26px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        max-width: 100%;
    }
    .imgs::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .price {
        font-size: 18px;
        height: 21px;
    }
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }
</style>
