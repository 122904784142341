<template>
    <div style="width: 100%;overflow: hidden">
        <div style="margin-top: 90px">
            <ShopInfo :myshop="myshop"></ShopInfo>
        </div>
        <div style="height: 100%;margin-top: 5px;">
            <!--   产品详情信息  -->
            <div style="display: flex;justify-content: center">
                <div style="width:1500px;height: 730px;background: #FFFFFF;">
                    <productSize :GoodsInfo="GoodsInfo" :roomId="$route.params.roomid" :questId="$route.params.questId"></productSize>
                </div>
            </div>
            <!--     相关配件   -->
            <div style="display: flex;justify-content: center;margin-top: 20px;">
                <div style="width: 1500px;height: 454px;background: #FFFFFF; ">
                    <productCorrelation></productCorrelation>
                </div>
            </div>

            <div style="display: flex;justify-content:center;">
                <div style="width: 1500px;margin-top: 20px;display: flex">
                    <!--   相似产品    -->
                    <div style="width: 250px;height: auto;overflow: hidden;background: #FFFFFF;margin-bottom: 20px;">
                        <similarProduct></similarProduct>
                    </div>
                    <!--   产品介绍   -->
                    <div style="width: 1230px;height: auto;background: #FFFFFF;margin-left: 20px">
                        <productIntroduction :GoodsInfo="GoodsInfo"></productIntroduction>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import productSize from "../shop/components/myShop/details/productSize";
import productCorrelation from "../shop/components/myShop/details/productCorrelation";
import similarProduct from "../shop/components/myShop/details/similarProduct";
import productIntroduction from "../shop/components/myShop/details/productIntroduction";
import ShopInfo from "./components/myShop/ShopInfo";
export default {
    components: {
        ShopInfo,
        productSize,
        productCorrelation,
        similarProduct,
        productIntroduction,
    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    created:function(){
    
    },
    data () {
        return {
            GoodsInfo:{},
            myshop:{},
        }
    },
    mounted: function() {
        this.getShopGoodsInfos();
    },
    methods: {
        // 商务信息详情
        getShopGoodsInfos:function(){
            var that = this;
            that.newApi.getShopGoodsInfo({
                goodsId:that.$route.params.id
            }).then((res)=>{
                that.GoodsInfo = res.data;
                that.getMyShop()
            }).catch((err)=>{
                console.log(err)
            })
        },
        getMyShop(){
            const that = this;
            that.newApi.getMyShop({
                id:that.GoodsInfo.shopId
            }).then((ret)=>{
                that.myshop = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>
