<template>
    <div style="width: 100%;height: 100%">
        <div v-for="(item,index) in shop_list" :key="index" style="margin-top: 10px;">
            <div @click="add_list(item.type)"  style="float: left;margin-left: 50px;height: 35px;" :class="{ active: item.type === cache }">
                <div class="pay-type-name" >{{item.name}}</div>
            </div>
        </div>
        <div style="width: 1230px;border-bottom: 2px solid #EBEBEB;padding: 17.5px"></div>
            <!--   服务介绍     -->
            <div v-if="cache === 1">
                <div style="min-height: 100px;width: 93%;margin: 10px auto;">
	                <el-descriptions class="margin-top" title="自定义属性" :column="4">
			                <el-descriptions-item :label="spec.key" v-for="(spec,index) in GoodsInfo.otherSpecifications" :key = "index">
				               <span v-if="spec.type===1 || spec.type===2">{{ spec.value }}</span>
				                <label v-if="spec.type===3 || spec.type===5">
					                <span  v-for="(radio,radioIndex) in spec.value" :key="radioIndex">
						                <span v-if="radio.radio===spec.radio">{{ radio.name }}</span>
					                </span>
				                </label>
				                <label v-if=" spec.type===4">
					                <span v-for="(box,radioIndex) in spec.value" :key="radioIndex">
						                <span v-if="spec.selectList.includes(box.radio)"><span v-if="radioIndex>0"> , </span>{{ box.name }}</span>
					                </span>
				                </label>
				                <label v-if=" spec.type===6">
					                <DynamicTable :value="spec.value" :lookTableValue = "true"></DynamicTable>
				                </label>
			                </el-descriptions-item>
	                </el-descriptions>
                </div>
                <div class="follow" style="float: left;font-weight:bold;">产品特点:</div>
                <div class="follow" style="text-indent: 2em;padding-top: 10px;margin-right: 15px;min-height: 100px" v-html="GoodsInfo.content"></div>
            </div>

            <!--  交易保障 -->
            <div v-if="cache == 2">
                <div style="min-height: 100px;">
                    <label v-if="GoodsInfo.commitment.length > 0">
                        <div style="float: left;margin: 20px 0 0 30px">
                            <img src="~@/assets/imgs/index/yusan.png" style="width: 26px;height: 26px;">
                        </div>
                        <div  style="font-size: 20px;height: 24px;color: #2970FF;float: left;margin: 18px 10px">商家承诺</div>
                    </label>
                    <div class="ply" style="clear: both;text-indent: 2em;color: #444444;font-size: 18px;" v-for="(i,k) in GoodsInfo.commitment" :key="k"><span style="font-size: 17px">{{k+1}}.</span>{{i}}</div>
                </div>
                <div style="min-height: 100px;clear: both">
                    <label v-if="GoodsInfo.install.length > 0">
                        <div style="float: left;margin: 20px 0 0 30px">
                            <img src="~@/assets/imgs/index/yusan.png" style="width: 26px;height: 26px;">
                        </div>
                        <div  style="font-size: 20px;height: 24px;color: #2970FF;float: left;margin: 18px 10px">产品设备安装要求</div>
                    </label>
                    <div v-for="(install,index) in GoodsInfo.install" :key="index" class="ply" style="clear: both;text-indent: 2em;color: #444444;font-size: 18px;"><span style="font-size: 17px">{{index+1}}.</span>{{install}}</div>
                </div>
                <div style="min-height: 100px;clear: both">
                    <label v-if="GoodsInfo.instructions.length > 0">
                        <div style="float: left;margin: 20px 0 0 30px">
                            <img src="~@/assets/imgs/index/yusan.png" style="width: 26px;height: 26px;">
                        </div>
                        <div  style="font-size: 20px;height: 24px;color: #2970FF;float: left;margin: 18px 10px">产品设备使用要求</div>
                    </label>
                    <div class="ply" v-for="(instructions,index) in GoodsInfo.instructions" :key="index" style="clear: both;text-indent: 2em;color: #444444;font-size: 18px;"><span style="font-size: 17px">{{index+1}}.</span>{{instructions}}</div>
                </div>
            </div>

            <!--   服务评价     -->
            <div v-if="cache == 3">
                <div style="display: flex; padding: 10px;">
                  <div style="text-align: center; margin-right: 20px; width: 20%">
                    <div style="font-size: 20px;color: #2970FF; font-weight: 600; margin: 10px 10px 10px 0">好评率</div>
                    <div style="font-size: 36px;color: #F12939; font-weight: 600">98%</div>
                  </div>
                  <div style="display: flex; flex-wrap: wrap">
                    <div v-for="(type,ind) in 10" :key="ind" >
                      <div style="border: 2px solid #E0E0E0;margin: 10px;">
                        <div style="font-size: 18px;padding: 2px 4px;color: #999999;">服务到位(10)</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 100%;height: 70px; line-height: 70px; background: #F8F8F8;">
                    <div style="float: left; padding: 0 20px">
                        <el-radio-group v-model="radio">
                            <el-radio :label="1">全部</el-radio>
                            <el-radio :label="2">有图</el-radio>
                            <el-radio :label="3">有追评</el-radio>
                        </el-radio-group>
                    </div>
                    <div style="color: #555555;font-size: 15px; float: right; margin-right: 20px">默认排序</div>
                </div>
                <div style="padding: 30px 50px">
                  <div v-for="(item, index) in appraiseList" :key="index">
                    <div style="width: 50px;height: 50px;float: left">
                      <img :src="item.userInfo.headIco" style="width:100%;height: 100%;border-radius: 50%;">
                    </div>
                    <div style="float: left; margin-left: 8px; width: 20%">
                      <div style="font-size: 18px; font-weight: 600">{{item.userInfo.nickName}}</div>
                      <div style="color: #999999; margin-top: 4px">{{item.updateTime}}</div>
                    </div>
                    <div style="float: left">
                      <el-rate style="vertical-align: middle" v-model="item.goodsScore" color="#FF0000" :disabled="true" :colors="['#E4393C', '#E4393C', '#e4393c']"></el-rate>
                      <div style="width: 88px;height: 19px;font-size: 16px;color: #999999;margin: 5px 0 0 0;float: left">规格：XL</div>
                      <div style="width: 88px;height: 19px;font-size: 16px;color: #999999;margin: 5px 0 0 20px;float: left">颜色：黑色</div>
                    </div>
                    <div style="font-size: 16px;clear: both;padding-top: 10px;">
                      <div>{{item.content}}</div>
                    </div>
                    <el-divider></el-divider>
                  </div>
                </div>
            </div>
    </div>
</template>
<script>
import DynamicTable from "@/pages/shop/components/DynamicTable.vue";

export default {
     props: ['GoodsInfo'],
    components: {
	    DynamicTable
	    
    },

    computed: {

    },
    data () {
        return {
            shop_list:[{name:"产品介绍",type:1},{name:"交易保障",type:2},{name:"产品评价",type:3}],
            cache:1,
            radio:1,

            appraiseList: [],
        }
    },
    mounted:function(){
    },
    methods:{
        // 获取商品评价
        getServiceAppraise() {
          this.newApi.getShopAndServiceEvaluateList({specificId: this.$route.params.id, type: 2}).then(res => {
            this.appraiseList = res.data
          })
        },

        add_list(type){
            this.cache = type;
            if (this.cache === 3) {
              this.getServiceAppraise()
            }
        },
    }
}
</script>
<style scoped>
.active {
    color: #2970FF;
    border-bottom: 2px solid #2970FF;
}
.pay-type-name {
    height: 21px;
    font-size: 18px;
    cursor: pointer;
}
.ply {
    margin: 10px 30px;
    font-size: 16px;
}
.follow {
    margin-top: 10px;
    clear: both;
    font-size: 18px;
    margin-left: 40px;
    height: auto;
    color: #444444;

}
.follow>>>img {
    max-width: 100%;
}
/deep/ .el-radio__label {
    font-size: 16px;
}
/deep/ .el-radio__inner {
    font-size: 16px;
}
/deep/ .el-descriptions{
    font-size: 18px;
}
</style>
